<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full">
    <v-card-title class="xflex xflex-row xjustify-between xitems-center xpx-0">
      <Filters
        :value="selectedYearMonth"
        @change="navigate"
        type="monthly"
        @refetch="fetch(selectedYearMonth)"
      >
      </Filters>
    </v-card-title>
    <PTOTable
      :loading="loading"
      :loading-more="loading_more"
      :items="items"
      @refetch="fetch(selectedYearMonth)"
      :empty-text="
        selectedType == 'all'
          ? `No PTO found for ${monthYearHuman}`
          : `No pending PTO found for ${monthYearHuman}`
      "
    >
    </PTOTable>
    <v-card-actions
      v-if="next_page_url"
      :class="[mdUp ? 'xabsolute xbottom-0 xh-[60px]' : '']"
      class="xw-full xflex xflex-col xjustify-center xitems-center"
    >
      <btn-tooltip
        tip="Load more"
        color="primary"
        outlined
        @click="fetchMore"
        :loading="loading_more"
        :disabled="loading_more"
      >
        Load more
      </btn-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import Filters from "../components/Filters.vue";
import PTOTable from "../components/PTOTable.vue";
export default {
  data() {
    return {
      loading: false,
      loading_more: false,
      items: [],
      selectedYearMonth: moment().format("YYYY-MM"),
      next_page_url: null,
    };
  },
  watch: {
    "$route.params.year_month": {
      handler: function (val) {
        this.selectedYearMonth = val;
        val && this.fetch(val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    selectedType() {
      return this.$route.name.includes("app-pto-all") ? "all" : "pending";
    },
    monthYearHuman() {
      if (!this.selectedYearMonth) return moment().format("MMMM YYYY");
      return moment(this.selectedYearMonth, "YYYY-MM").format("MMMM YYYY");
    },
  },
  mounted() {
    this.$event.$on("refetch-ptos", () => {
      this.fetch(this.selectedYearMonth);
    });
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: `app-pto-${this.selectedType}-monthly`,
          params: { year_month: val },
        })
        .catch(() => {});
    },
    fetch(year_month) {
      this.loading = true;
      this.$axios
        .get(`api/ptos/monthly/${year_month}?type=${this.selectedType}`)
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    fetchMore() {
      this.loading_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading_more = false));
    },
  },
  components: { Filters, PTOTable },
};
</script>

<style lang="scss" scoped></style>
